<template>
  <div>
    <shipblu-card :cardLoading="trackingCardLoading" class="p-5">
      <div class="md:flex justify-between gap-2">
        <div>
          <div class="flex items-center gap-5"> 
            <p class="header">{{ $t('tracking order') }}</p>
            <div class="cursor-pointer flex gap-1 items-start">
              <div class="flex gap-2">
                <p class="text-darkgray text-lg">{{ orderData.tracking_number }}</p>
                <div class="flex items-center">
                  <span @click="copied(orderData.tracking_number ,'orderData')" class="material-icons-round text-grey hover:text-primary text-2xl cursor-pointer">copy_all</span>
                  <span v-if="trackingCopied" class="text-xs font-medium">{{ $t('Copied') }}</span>
                  <span v-if="orderData.is_exchange" class="flex items-center">
                    <span class="material-symbols-outlined text-primary mx-2 font-extralight" style="font-weight:200;">swap_horiz</span>
                    <span class="text-darkgray">{{$route.params.type === 'delivery-orders' ? orderData.linked_exchange_order.return_order.tracking_number : orderData.linked_exchange_order.delivery_order.tracking_number}}</span>
                    <span @click="copied($route.params.type === 'delivery-orders' ? orderData.linked_exchange_order.return_order.tracking_number : orderData.linked_exchange_order.delivery_order.tracking_number ,'exchangeOrder')" class="material-icons-round text-grey hover:text-primary text-2xl cursor-pointer ml-2">copy_all</span>
                    <span v-if="exchangeCopied" class="text-xs font-medium">{{ $t('Copied') }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-4 mt-1">
            <div class="text-primary cursor-pointer flex gap-1 items-start mt-1">
              <img class="" @click="trackingActive = true" src="@/assets/images/pages/eye.gif" width="20px" alt="">
              <p @click="trackingActive = true" class="underline text-sm">{{ $t('View Detailed Timeline') }}</p>
            </div>
          </div>
        </div>
        <div class="md:mt-0 mt-3 flex flex-wrap items-center gap-2">
          <div v-if="ringerHistory.length > 0 && ($store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'manager' || $store.state.AppActiveUser.userRole === 'head-of-fleet' || $store.state.AppActiveUser.userRole === 'fc-operations-supervisor')">
            <vs-button class="font-medium py-1 px-2 h-9" @click="ringerModal = true">
              <div class="flex items-center gap-1 leading-tight">
                <p class="material-icons-round text-2xl">history</p>
                <p class="text-sm">{{ $t('Ringer') }}</p>
              </div>
            </vs-button>
          </div>
          <div v-if="$store.state.AppActiveUser.userRole !== 'merchant'">
            <div class="flex items-center font-semibold gap-1 mt-1" v-if="orderData.bluscore && (orderData.bluscore.pred === 0 || orderData.bluscore.pred === -1 || orderData.bluscore.pred === 1)">
              <p class="bg-gradient-to-r from-black via-blue-100 to-blue-100 inline-block text-transparent bg-clip-text">{{ $t('BluScore:') }}</p>
              <p :class="orderData.bluscore.pred === 1 ? 'text-green' : orderData.bluscore.pred === -1 ? 'text-black' : 'text-red'">{{ orderData.bluscore.pred === -1 ? 'N/A' : parseInt(orderData.bluscore.prob * 100) + '%' }}</p>
            </div>
          </div>
          <div v-if="localStorage.getItem('multipleWarehouses') === 'true' && ($store.state.AppActiveUser.userRole !== 'merchant' && $store.state.AppActiveUser.userRole !== 'customer-support' && $store.state.AppActiveUser.userRole !== 'finance' && $store.state.AppActiveUser.userRole !== 'sales')">
            <vs-button type="border" class="font-medium pb-1 pt-2 px-2" @click="openTrackingEventModal">
              <div class="flex items-center gap-1">
                <feather-icon icon="PlusIcon" svgClasses="w-5 h-5" />
                <p class="text-sm">{{ $t('Add Tracking Event') }}</p>
              </div>
            </vs-button>
          </div>
        </div>
      </div>

      <!-- Delivery Order Timeline -->
      <delivery-order-timeline v-if="$route.params.type === 'delivery-orders'" :orderData="orderData" :trackings="trackings" />

      <div v-else class="mt-10 live-tracking">
        <div v-for="(item, index) in orderLiveTracking" :key="index" class="tracking-time-line">
          <div v-if="index !== 0" :class="index <= lastActiveStatus ? 'active-line' : 'inactive-line'" class="line"></div>
          <p :class="index <= lastActiveStatus ? 'text-primary' : 'text-grey'" class="material-icons-round icon text-2xl">check_circle</p>
          <div class="md:mb-2 mb-1 md:mx-1">
            <p class="inline-block whitespace-nowrap text-white text-sm capitalize py-1 px-2 rounded-4xl" :style="{background: index <= lastActiveStatus ? getOrderStatusColor(item) : '#B8C2CC'}">
              {{ $t(getStatusLabel(item)) }}
            </p>
          </div>
          <div class="md:block flex gap-2 justify-between items-center">
            <p v-if="$store.state.AppActiveUser.userRole !== 'merchant'" class="text-sm text-darkgray font-medium">
              {{ trackings.filter(element => element.status === item.status && element.user).length > 0 ? trackings.slice().reverse().filter(element => element.status === item.status && element.user)[0].user.first_name + ' ' + 
              trackings.slice().reverse().filter(element => element.status === item.status && element.user)[0].user.last_name : '' }}
            </p>
            <div v-if="(item.status === 'delivered' || item.status === 'returned') && (orderData.status !== 'delivered' && orderData.status !== 'returned')" class="text-xs text-darkgray shrink-0">
              <p>{{ orderData.estimated_date ? orderData.estimated_date : '' }}</p>
              <p>{{ orderData.estimated_date ? $t('expected delivery date') : '' }}</p>
            </div>            
            <p v-else class="text-xs text-darkgray shrink-0">{{ trackings.filter(element => element.status === item.status).length > 0 ? 
              new Date(trackings.slice().reverse().filter(element => element.status === item.status)[0].created).toLocaleDateString('fr-CA') + ' ' +
              new Date(trackings.slice().reverse().filter(element => element.status === item.status)[0].created).toLocaleTimeString() : '' }}</p>
          </div>
        </div>
      </div>
    </shipblu-card>

    <!-- TRACKING EVENT PROMPT -->
    <shipblu-prompt
    class="tracking-modal"
    @close="closeModal()"
    @cancel="closeModal()"
    :active.sync="trackingEventModal"
    :title="$t('Add Tracking Event')"
    :buttons-hidden="true">
      <div>
        <p class="font-medium text-gray leading-tight mb-1">{{ $t('Tracking Event') }}</p>
        <v-select class="w-full" name="tracking" v-model="status" :placeholder="$t('Tracking Event')" v-validate="'required'" label="name" :options="$route.params.type === 'delivery-orders' ? trackingEventsDelivery : $route.params.type === 'cash-collections' ? trackingEventsCash : trackingEventsReturns"/>
        <span class="text-danger text-sm" v-show="errors.has('tracking')">{{ errors.first('tracking') }}</span>
      </div>
      <div v-if="status && (status.value === 'in_transit' || status.value === 'collected')" class="mt-4">
        <p class="font-medium text-gray leading-tight mb-1">{{ $t('Warehouse') }}</p>
        <v-select class="w-full" name="warehouse" v-model="warehouse" :placeholder="$t('Warehouse')" v-validate="'required'" label="name" :options="warehouses"/>
        <span class="text-danger text-sm" v-show="errors.has('warehouse')">{{ errors.first('warehouse') }}</span>
      </div>
      <div v-if="status && (status.value === 'return_attempted' || status.value === 'delivery_attempted' || status.value === 'collection_attempted')" class="mt-4">
        <p class="text-lg font-medium">{{ $route.params.type === 'cash-collections' ? $t('FAILED COLLECT') : $t('FAILED DELIVERY') }} </p>
        <div class="px-2">
          <p class="my-1 font-medium text-darkgray">{{ $t(failedDeliveryQuestion) }}:</p>
          <ul class="centerx">
            <li class="text-sm text-black" v-for="choose in (trackings.filter(item => item.status === 'delivery_attempted')).length === 2 ? failedDeliveryAnswersOptions.filter(item => item.text !== 'Customer rescheduled' && item.text !== 'Customer request to change address') : failedDeliveryAnswersOptions" :key="choose.index">
              <vs-radio name="reason" v-validate="'required'" v-model="answer" :vs-value="choose.value">{{$t(choose.text)}}</vs-radio>
            </li>
          </ul>
          <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first('reason') }}</span>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-7">
        <button @click="closeModal()" class="btn disable-btn">{{ $t('Cancel') }}</button>
        <button @click="buttonClicked" class="active-btn btn">{{ $t('Add') }}</button>
      </div>
    </shipblu-prompt>

    <!-- Ringer PROMPT -->
    <shipblu-prompt
    class="tracking-modal"
    @close="ringerModal = false"
    @cancel="ringerModal = false"
    :active.sync="ringerModal"
    :title="$t('Calls History')"
    :buttons-hidden="true">
      <vs-collapse class="ringer-collapse p-0">
        <vs-collapse-item class="mb-2 pb-2" v-for="(item, index) in ringerHistory.filter((value, index, self) => index === self.findIndex((item) => (item.created.split('T')[0] === value.created.split('T')[0])))" :key="index">
          <div slot="header">
            <p class="font-semibold text-base">
              <span class="text-primary">{{ new Date(item.created).toLocaleDateString('fr-CA') }}</span>
              <span class="text-darkgray ml-1">{{ '(' + daysDic[new Date(item.created.split('T')[0]).getDay()] + ')' }}</span>
            </p>
          </div>
          <div v-for="(record, index) in ringerHistory.filter(element => element.created.split('T')[0] === item.created.split('T')[0])" :key="index">
            <audio class="w-full h-12 mt-2" controls :src="record.recording_file_link"></audio>
            <p class="text-sm font-medium text-black text-right px-5">
              <span class="mr-2">{{ new Date(item.created).toLocaleTimeString() }}</span>
              <span>{{ item.caller.first_name }} {{ item.caller.last_name }}</span>
            </p>
          </div>
        </vs-collapse-item>
      </vs-collapse>
      <div class="text-center mt-5">
        <button @click="ringerModal = false" class="active-btn btn w-3/5">{{ $t('Done') }}</button>
      </div>
    </shipblu-prompt>

    <!-- SIDE TRACKING -->
    <div @click="checkElementClicked" :class="trackingActive ? 'tracking-container' : ''">
      <div class="tracking-card" :class="trackingActive ? 'show-tracking-card' : 'hide-tracking-card'">
        <div class="p-4 flex items-center justify-between">
          <p class="text-primary text-lg font-medium">{{ $t('Detailed Timeline') }}</p>
          <feather-icon @click="trackingActive = false" class="cursor-pointer text-gray" icon="XSquareIcon" svgClasses="h-5 w-5"/>
        </div>
        <vs-divider class="m-0 px-4 pb-2" />
        <!-- ORDRES LOGS -->
        <div class="text-right px-10 py-5" v-if="$store.state.AppActiveUser.userRole === 'admin' || $store.state.AppActiveUser.userRole === 'customer-support' || $store.state.AppActiveUser.userRole === 'head-of-fleet'">
          <p @click="loadLogs" class="cursor-pointer text-primary text-sm"> 
            <feather-icon style="vertical-align: middle;" class="cursor-pointer mr-1" icon="DownloadIcon" svgClasses="h-4 w-4"/>
            <span class="underline">{{ $t('Export Logs') }} </span>
          </p>
        </div>
        <div class="content p-4">
          <div class="flex gap-6" v-for="(item, index) in trackings" :key="index">
            <div class="w-32 sm:block hidden shrink-0 text-right">
              <p class="font-semibold">{{ new Date(item.created).toLocaleDateString('fr-CA') }} </p>
              <p class="font-medium">{{ new Date(item.created).toLocaleTimeString()  }}</p>
            </div>
            <div class="relative time-line pb-6 min-h-24 w-full" :class="index !== trackings.length - 1 ? 'timeline-bordered' : ''">
              <div class="dot"></div>
              <p class="inline-block text-white text-sm capitalize py-1 px-2 rounded-4xl font-medium mb-1" :style="{background: getOrderStatusColor(item)}">{{ $t(getStatusLabel(item)) }}</p>
              <p v-if="$store.state.AppActiveUser.userRole !== 'merchant'" class="text-sm text-grey-dark font-medium">
                {{ item.user ? item.user.first_name + ' ' + item.user.last_name + ' ' + (item.user.hr_id ? `(${item.user.hr_id})` : '') : '' }}
                <span @click="removeRTO()" v-if="item.status === 'return_to_origin' && orderData.rto_requested && $store.state.AppActiveUser.userRole === 'head-of-fleet' && localStorage.getItem('multipleWarehouses') === 'true'" class="text-primary underline cursor-pointer text-xs block"> {{ $t('Release RTO') }} </span>
              </p>
              <div v-if="item.status === 'delivery_attempted' || item.status === 'collection_attempted' || item.status === 'return_attempted'">
                <p class="text-xs font-medium text-grey-dark">{{ $t(item.comment) }}</p>
              </div>
              <p class="text-sm text-grey-dark shrink-0 sm:hidden block">
                <span class="mr-2">{{ new Date(item.created).toLocaleDateString('fr-CA') }}</span>
                <span>{{ new Date(item.created).toLocaleTimeString() }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import common from '../../../assets/utils/common'
import i18nData from '../../../i18n/i18nData'
import ShipbluPrompt from '@/layouts/components/ShipBluPrompt.vue'
import { sendRequest } from '../../../http/axios/requestHelper'
import vSelect from 'vue-select'
import DeliveryOrderTimeline from './DeliveryOrderTimeline.vue'

export default {
  props: ['orderData', 'trackings', 'warehouses', 'trackingCardLoading', 'ringerHistory'],
  data () {
    return {
      exchangeOrder: {},
      localStorage,
      trackingActive: false,
      trackingEventModal: false,
      trackingCopied: false,
      exchangeCopied: false,
      orderLiveTracking: [],
      lastActiveStatus: 0,
      daysDic: {
        '0':'Sunday',
        '1':'Monday',
        '2':'Tuesday', 
        '3':'Wednesday',
        '4':'Thursday', 
        '5':'Friday',
        '6':'Saturday'
      },
      returnStatus: [
        {
          status: 'created'
        },
        {
          status: 'return_requested'
        },
        {
          status: 'picked_up'
        },
        {
          status: 'out_for_return'
        },
        {
          status: 'returned'
        },
        {
          status: 'cancelled'
        }
      ],
      cashCollectionStatus: [
        {
          status: 'created'
        },
        {
          status: 'collection_requested'
        },
        {
          status: 'out_for_collection'
        },
        {
          status: 'collected'
        }
      ],
      trackingEventsDelivery: [
        {
          name: 'Pickup Requested',
          value: 'pickup_requested'
        },
        {
          name: 'Picked Up',
          value: 'picked_up'
        },
        {
          name: 'In Transit',
          value: 'in_transit'
        },
        {
          name: 'Out For Delivery',
          value: 'out_for_delivery'
        },
        {
          name: 'Delivery Attempted',
          value: 'delivery_attempted'
        },
        {
          name: 'Return To Origin',
          value: 'return_to_origin'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      trackingEventsReturns: [
        {
          name: 'Return Requested',
          value: 'return_requested'
        },
        {
          name: 'Picked Up',
          value: 'picked_up'
        },
        {
          name: 'In Transit',
          value: 'in_transit'
        },
        {
          name: 'Out For Return',
          value: 'out_for_return'
        },
        {
          name: 'Return Attempted',
          value: 'return_attempted'
        },
        {
          name: 'Returned',
          value: 'returned'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      trackingEventsCash: [
        {
          name: 'Collection Requested',
          value: 'collection_requested'
        },
        {
          name: 'Failed',
          value: 'failed'
        },
        {
          name: 'Collection Attempted',
          value: 'collection_attempted'
        },
        {
          name: 'Out For Collection',
          value: 'out_for_collection'
        },
        {
          name: 'Collected',
          value: 'collected'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        }
      ],
      failedDeliveryAnswersOptions: [
        {
          text: 'Customer\'s phone is turned off',
          value: 'Customer\'s phone is turned off'
        },
        {
          text: 'Shipment doesn\'t follow the packaging guidelines',
          value: 'Shipment doesn\'t follow the packaging guidelines'
        },
        {
          text: 'Wrong address provided',
          value: 'Wrong address provided'
        },
        {
          text: 'Address not completed',
          value: 'Address not completed'
        },
        {
          text: 'No one at home',
          value: 'No one at home'
        },
        {
          text: 'Out Of Zone',
          value: 'Out Of Zone'
        },
        {
          text: 'Customer rescheduled',
          value: 'Customer rescheduled'
        },
        {
          text: 'Customer request to change address',
          value: 'Customer request to change address'
        },
        {
          text: 'Customer refused to accept delivery',
          value: 'Customer refused to accept delivery'
        },
        {
          text: 'Customer didn\'t have Money',
          value: 'Customer didn\'t have Money'
        },
        {
          text: 'Customer evade to receive',
          value: 'Customer evade to receive'
        },
        {
          text: 'Customer is not answering our calls',
          value: 'Customer is not answering our calls'
        },
        {
          text: 'Packaging does not follow ShipBlu guidelines',
          value: 'Packaging does not follow ShipBlu guidelines'
        },
        {
          text: 'Customer Wants to open the package',
          value: 'Customer Wants to open the package'
        }
      ],
      failedDeliveryQuestion: 'Choose reason for failed delivery',
      answer: '',
      status: '',
      warehouse: '',
      multipleWarehouses: localStorage.getItem('multipleWarehouses'),
      ringerModal: false,
      userInfo: {}
    }
  },
  watch: {
    orderData () {
      if (this.orderData.rto_requested) {
        this.trackingEventsDelivery.push({
          name: 'Returned',
          value: 'returned'
        }) 
      }
      if (this.orderData.fulfillment_provider_metadata) {
        if (this.orderData.tracking_number[0] === '1' || (this.orderData.tracking_number[0] === '3' && this.orderData.tracking_number[1] === '1')) {
          this.trackingEventsDelivery.shift()
        }
        if (this.orderData.tracking_number[0] === '2' || (this.orderData.tracking_number[0] === '3' && this.orderData.tracking_number[1] === '2')) {
          this.trackingEventsReturns.shift()
        }
      }
    },
    'orderData.cash' (val) {
      if (Number(val) > 0 && this.trackingEventsDelivery.filter(item => item.name === 'Delivered').length === 0) {
        this.trackingEventsDelivery.push({
          name: 'Delivered',
          value: 'delivered'
        })
      } else if (Number(val) === 0 && this.trackingEventsDelivery.filter(item => item.name === 'Delivered').length > 0) {
        this.trackingEventsDelivery.forEach((item, index) => {
          if (item.name === 'Delivered') this.trackingEventsDelivery.splice(index, 1)
        })
      }
    },
    trackings () {
      if (this.orderData.tracking_number[0] === '2' || (this.orderData.tracking_number[0] === '3' && this.orderData.tracking_number[1] === '2')) {
        if (this.trackings.filter(item => item.status === 'return_attempted').length > 0 && this.returnStatus.filter(item => item.status === 'return_attempted').length === 0) {
          this.returnStatus.splice(3, 0, {status: 'return_attempted'})
        }
        if (this.trackings.filter(item => item.status === 'cancelled').length > 0) {
          this.returnStatus.splice(this.trackings.filter(item => item.status === 'return_attempted').length > 0 ? 5 : 4, 1)
        }
        this.orderLiveTracking = this.returnStatus
      }
      if (this.orderData.tracking_number[0] === '4') {
        if (this.trackings.filter(item => item.status === 'collection_attempted').length > 0 && this.cashCollectionStatus.filter(item => item.status === 'delivery_attempted').length === 0) {
          this.cashCollectionStatus.splice(3, 0, {status: 'collection_attempted'})
        }
        this.orderLiveTracking = this.cashCollectionStatus
      }
      let isInTransitLastStatus = 0 
      for (let i = 0; i < this.orderLiveTracking.length; i++) {
        if (this.trackings.filter(element => element.status === this.orderLiveTracking[i].status).length > 0) {
          this.lastActiveStatus = i
        }
      }
      if (this.trackings[0] && this.trackings[0].status === 'in_transit' && this.trackings.filter(element => element.status === 'in_transit').length === 1 && this.trackings.filter(element => element.status === 'picked_up').length === 0) {
        isInTransitLastStatus = 1
      }
      this.lastActiveStatus = isInTransitLastStatus + this.lastActiveStatus
      if (this.orderData.status === 'delivered' || this.orderData.status === 'returned') {
        this.orderLiveTracking.pop()
      }
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    async copied (data, type) {
      try {
        await navigator.clipboard.writeText(data)
        type === 'orderData' ? this.trackingCopied = true : this.exchangeCopied = true        
        setTimeout(() => {
          type === 'orderData' ? this.trackingCopied = false : this.exchangeCopied = false        
        }, 750)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    },
    checkElementClicked (event) {
      if (event.target.classList[0] === 'tracking-container') {
        this.trackingActive = false
      }
    },
    openTrackingEventModal () {
      this.$validator.resume()
      this.trackingEventModal = true
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order.status ? order : order.status, this.$store.state.AppActiveUser.userRole)
    },
    buttonClicked () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if ((this.orderData.is_paid === false && this.status.value === 'delivered' && parseInt(this.orderData.cash_amount) > 0) || (this.status.value === 'collected' && parseInt(this.orderData.amount) > 0)) {
            this.confirmCashCollected()
          } else {
            this.addTracking()
          }
        }
      })
    },
    addTracking () {
      let url = ''
      const trackings = []
      let attempt_questions = []
      if (this.status.value === 'return_attempted' || this.status.value === 'delivery_attempted' || this.status.value === 'collection_attempted') {
        attempt_questions = [
          {
            question: this.failedDeliveryQuestion,
            answer: this.answer
          }
        ]
      }
      if (this.$route.params.type === 'cash-collections') {
        url = 'api/v1/tracking/cash-collections/'
        trackings.push({
          cash_collection_request: this.$route.params.orderID,
          status: this.status.value,
          attempt_questions
        })
      } else {
        url = 'api/v1/tracking/'
        trackings.push(
          {
            order: this.$route.params.orderID,
            packages: this.orderData.packages.map(item => item.package.id),
            status: this.status.value,
            attempt_questions
          }
        )
      }
      if (this.status.value === 'in_transit') {
        trackings[0].current_warehouse = this.warehouse.id
      }
      sendRequest(true, false, this, url, 'post', trackings, true,
        () => {
          this.closeModal()
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.$emit('loadOrder', 'trackingCardLoading')
        }
      )
    },
    loadLogs () {
      this.$emit('loadLogs')
    },
    confirmCashCollected () {
      const transaction = {
        amount: this.orderData.cash_amount ? Number(this.orderData.cash) : Number(this.orderData.amount),
        type: 'deposit',
        by: this.userInfo.name,
        reference_number: `${this.orderData.customer.address.zone.city.governorate.name} | ${this.orderData.tracking_number} | ${new Date().toISOString().slice(0, 10)}`
      }
      if (this.status.value === 'delivered') {
        this.warehouse = this.orderData.outbound_warehouse.id
      } else {
        this.warehouse = this.warehouse.id
      }
      sendRequest(true, false, this, `api/v1/accounting/khazna/${this.warehouse}/transactions/`, 'post', transaction, true,
        () => {
          this.addTracking()
        }
      )
    },
    removeRTO () {
      sendRequest(true, false, this, `api/v1/${this.$route.params.type}/${this.$route.params.orderID}/`, 'patch', { rto_requested: false}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.trackingActive = false
          this.$emit('loadOrder')
        }) 
    },
    closeModal () {
      this.$validator.pause()
      this.trackingEventModal = false
      this.answer = ''
      this.status = ''
      this.warehouse = ''
    }
  },
  components: {
    ShipbluCard,
    ShipbluPrompt,
    vSelect,
    DeliveryOrderTimeline
  },
  created () {
    this.userInfo = localStorage.getItem('idTokenPayload') ? JSON.parse(localStorage.getItem('idTokenPayload')) : {}
    if (this.$store.state.AppActiveUser.userRole === 'head-of-fleet' && localStorage.getItem('multipleWarehouses') === 'true') {
      this.trackingEventsReturns.push({
        name: 'Lost',
        value: 'lost'
      },
      {
        name: 'Damaged',
        value: 'damaged'
      })
      this.trackingEventsDelivery.push({
        name: 'Lost',
        value: 'lost'
      },
      {
        name: 'Damaged',
        value: 'damaged'
      })
    }
  }
}
</script>

<style lang="scss">
.shrink-0 {
  flex-shrink: 0;
}
.tracking-container {
  position: fixed;
  inset: 0;
  z-index: 99999;
  background-color: rgba(0, 57, 168, 0.21);
  transition: 0.3s ease;
}
.tracking-card {
  position: fixed;
  right: 0;
  top: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 10px rgba(28, 91, 254, 0.18);
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  width: 440px;
  height: 100vh;
  & > div.content {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}
.hide-tracking-card {
  position: fixed;
  z-index: 999999;
  right: -455px;
  transition: all 0.3s ease;
}
.show-tracking-card {
  position: fixed;
  z-index: 999999;
  right: 0px;
  transition: all 0.3s ease;
}
@media (max-width: 575px) {
  .tracking-card {
    width: 310px;
  }
  .hide-tracking-card {
    right: -325px;
  }
}
.time-line {
  padding-left: 25px;
  border-left: 2px dashed #fff;
  .dot {
    position: absolute;
    left: -8px;
    background: #BAC2CB;
    border-radius: 50%;
    width: 14px;
    height: 14px;
  }
}
.timeline-bordered {
  border-left: 2px dashed #BAC2CB;
}
div.tracking-modal {
  .vs-dialog{
    max-width: 450px !important;
  }
  .vs-dialog {
    .vs-dialog-text {
      padding-top: 0px;
    }
  }
}
.live-tracking {
  display: flex;
  .tracking-time-line {
    width: 100%;
    text-align: center;
    position: relative;
    .icon {
      position: absolute;
      left: calc(50% - 10.5px);
      top: -24px;
      background: #fff;
      z-index: 1;
    }
  }
  .line {
    position: relative;
    top: -15px;
    left: -50%;
  }
  .active-line {
    border-top: 2px solid #1C5BFE;
  }
  .inactive-line {
    border-top: 2px dashed #B8BFC6;
  }
}
@media(max-width: 900px) {
  .live-tracking {
    display: block !important;
  }
  .tracking-time-line {
    min-height: 75px;
    width: calc(100% - 40px) !important;
    text-align: left !important;
    position: relative;
    left: 40px;
    .icon {
      position: absolute;
      left: -40px !important;
      top: 2.5px !important;
      background: #fff;
      z-index: 1;
    }
  }
  .line {
    height: 100%;
    position: absolute !important;
    top: -62.5px !important;
    left: -30.5px !important;
  }
  .active-line {
    border-top: 0px !important;
    border-left: 2px solid #1C5BFE;
  }
  .inactive-line {
    border-top: 0px !important;
    border-left: 2px dashed #B8BFC6;
  }
}
.ringer-collapse {
  .vs-collapse-item--header {
    padding: 6px 8px !important;
    .icon-scale {
      transform: scale(1.2);
    }
  }
  .con-content--item {
    padding: 0px !important;
  }
}
</style>